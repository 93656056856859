import React from 'react'
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControlLabel, isNullOrEmpty,
    Spacer,
    Switch,
    TextField,
    Typography
} from "@emburse/embark-core";
import {useTranslation} from "react-i18next";
import {ExpenseSettings, RouteLink, UserSettings} from "enums";
import {ConfigurationContext} from "providers";
import {BottomNavBar, CheckBoxTypography, SwitchTypography, ViewContainer} from "components";
import secureLocalStorage from "react-secure-storage";
import {
    getDefaultValue,
    getValueByKey,
    getExpensesFromLocalStorage,
    textFieldPattern,
    isValuesExistsKeyMap, digitPattern
} from "utils";
import {Controller, useForm, useWatch} from "react-hook-form";
import {IExportExpense, IImportExpense, IVendorBillApprovalStatus} from "models";

export const Expenses = () => {
    const {t} = useTranslation();
    const {
        companyId,
        featureFlags,
        configurationId,
        setConfiguration,
        countriesList,
        netSuiteCustomExpenseFields
    } = React.useContext(ConfigurationContext);

    const syncEmployees = secureLocalStorage.getItem(companyId + "_" + configurationId + "_" + UserSettings.SYNC_EMPLOYEES);

    const {
        register,
        clearErrors,
        control,
        formState,
        setValue,
        setError,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            importSettings: {
                importExpenseCategoriesEnabled: getExpensesFromLocalStorage(companyId, configurationId).importExpenseSettings?.importExpenseCategoriesEnabled,
                synchronizeNetsuiteExpenseCustomFieldsEnabled: getExpensesFromLocalStorage(companyId, configurationId).importExpenseSettings?.synchronizeNetsuiteExpenseCustomFieldsEnabled,
                netsuiteCustomFieldsToImport: getExpensesFromLocalStorage(companyId, configurationId).importExpenseSettings?.netsuiteCustomFieldsToImport,
                multipleCurrenciesEnabled: getExpensesFromLocalStorage(companyId, configurationId).importExpenseSettings?.multipleCurrenciesEnabled,
                netsuiteSuiteTaxesEnabled: getExpensesFromLocalStorage(companyId, configurationId).importExpenseSettings?.netsuiteSuiteTaxesEnabled,
                netsuiteTaxableRegions: getExpensesFromLocalStorage(companyId, configurationId).importExpenseSettings?.netsuiteTaxableRegions,
                overrideForeignAmountAndExchangeRateEnabled: getExpensesFromLocalStorage(companyId, configurationId).importExpenseSettings?.overrideForeignAmountAndExchangeRateEnabled
            },
            exportSettings: {
                exportExpenseReportsEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.exportExpenseReportsEnabled,
                expenseReportFormId: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.expenseReportFormId,
                reportNumberPrefix: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.reportNumberPrefix,
                exportExpenseReportAsCompleteEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.exportExpenseReportAsCompleteEnabled,
                settSupervisorApprovalEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.settSupervisorApprovalEnabled,
                settAccountingApprovalEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.settAccountingApprovalEnabled,
                payableAccount: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.payableAccount,

                exportCompanyPaidExpensesEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.exportCompanyPaidExpensesEnabled,
                expenseReportIncludingCorporateCardsEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.expenseReportIncludingCorporateCardsEnabled,
                corporateCardAccount: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.corporateCardAccount,

                exportAsVendorBillsEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.exportAsVendorBillsEnabled,
                vendorBillFormId: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.vendorBillFormId,
                vendorCreditFormId: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.vendorCreditFormId,
                vendorBillApprovalStatus: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.vendorBillApprovalStatus,
                vendorBillCreditCardsEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.vendorBillCreditCardsEnabled,
                expenseReceiptsEnabled: getExpensesFromLocalStorage(companyId, configurationId).exportExpenseSettings?.expenseReceiptsEnabled
            }
        }
    });

    const values = useWatch({control});
    const {errors} = formState;

    const vendorBillApprovalStatus: IVendorBillApprovalStatus = {
        default: t("defaultVendorBillApprovalStatusOption"),
        pending: t("pendingVendorBillApprovalStatusOption"),
        approved: t("approvedVendorBillApprovalStatusOption")
    };

    const getDefaultVendorBillApprovalStatus = (value: any) => {
        let defaultValue: string = getDefaultValue(value);
        return defaultValue ?? Object.keys(vendorBillApprovalStatus)[0];
    }

    const getImportExpenseSettings = (): IImportExpense => {
        return {
            importExpenseCategoriesEnabled: values.importSettings?.importExpenseCategoriesEnabled,
            synchronizeNetsuiteExpenseCustomFieldsEnabled: values.importSettings?.synchronizeNetsuiteExpenseCustomFieldsEnabled,
            netsuiteCustomFieldsToImport: values.importSettings?.netsuiteCustomFieldsToImport,
            multipleCurrenciesEnabled: values.importSettings?.multipleCurrenciesEnabled,
            netsuiteSuiteTaxesEnabled: values.importSettings?.netsuiteSuiteTaxesEnabled,
            netsuiteTaxableRegions: values.importSettings?.netsuiteTaxableRegions,
            overrideForeignAmountAndExchangeRateEnabled: values.importSettings?.overrideForeignAmountAndExchangeRateEnabled
        }
    }

    const getExportExpenseSettings = (): IExportExpense => {
        return {
            exportExpenseReportsEnabled: values.exportSettings?.exportExpenseReportsEnabled,
            expenseReportFormId: values.exportSettings?.expenseReportFormId,
            reportNumberPrefix: values.exportSettings?.reportNumberPrefix,
            exportExpenseReportAsCompleteEnabled: values.exportSettings?.exportExpenseReportAsCompleteEnabled,
            settSupervisorApprovalEnabled: values.exportSettings?.settSupervisorApprovalEnabled,
            settAccountingApprovalEnabled: values.exportSettings?.settAccountingApprovalEnabled,
            payableAccount: values.exportSettings?.payableAccount,
            exportCompanyPaidExpensesEnabled: values.exportSettings?.exportCompanyPaidExpensesEnabled,
            expenseReportIncludingCorporateCardsEnabled: values.exportSettings?.expenseReportIncludingCorporateCardsEnabled,
            corporateCardAccount: values.exportSettings?.corporateCardAccount,
            exportAsVendorBillsEnabled: values.exportSettings?.exportAsVendorBillsEnabled,
            vendorBillFormId: values.exportSettings?.vendorBillFormId,
            vendorCreditFormId: values.exportSettings?.vendorCreditFormId,
            vendorBillApprovalStatus: values.exportSettings?.vendorBillApprovalStatus,
            vendorBillCreditCardsEnabled: values.exportSettings?.vendorBillCreditCardsEnabled,
            expenseReceiptsEnabled: values.exportSettings?.expenseReceiptsEnabled
        }
    }

    React.useEffect(() => {
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPENSE_IMPORT_CATEGORIES_ENABLED, values.importSettings?.importExpenseCategoriesEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.SYNCHRONIZE_NETSUITE_EXPENSE_CUSTOM_FIELDS_ENABLED, values.importSettings?.synchronizeNetsuiteExpenseCustomFieldsEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.NETSUITE_CUSTOM_FIELDS_TO_IMPORT, values.importSettings?.netsuiteCustomFieldsToImport as string[]);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.MULTIPLE_CURRENCIES_ENABLED, values.importSettings?.multipleCurrenciesEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.NETSUITE_SUITE_TAXES_ENABLED, values.importSettings?.netsuiteSuiteTaxesEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.NETSUITE_TAXABLE_REGIONS, values.importSettings?.netsuiteTaxableRegions as string[]);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.OVERRIDE_FOREIGN_AMOUNT_AND_EXCHANGE_RATE_ENABLED, values.importSettings?.overrideForeignAmountAndExchangeRateEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_EXPENSE_REPORT_ENABLED, values.exportSettings?.exportExpenseReportsEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPENSE_REPORT_FORM_ID, values.exportSettings?.expenseReportFormId as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.REPORT_NUMBER_PREFIX, values.exportSettings?.reportNumberPrefix as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_EXPENSE_REPORT_AS_COMPLETE_ENABLED, values.exportSettings?.exportExpenseReportAsCompleteEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.SET_SUPERVISOR_APPROVAL_ENABLED, values.exportSettings?.settSupervisorApprovalEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.SET_ACCOUNTING_APPROVAL_ENABLED, values.exportSettings?.settAccountingApprovalEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.PAYABLE_ACCOUNT, values.exportSettings?.payableAccount as string);

        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_COMPANY_PAID_EXPENSES_ENABLED, values.exportSettings?.exportCompanyPaidExpensesEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPENSE_REPORT_INCLUDING_CORPORATE_CARDS_ENABLED, values.exportSettings?.expenseReportIncludingCorporateCardsEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.CORPORATE_CARD_ACCOUNT, values.exportSettings?.corporateCardAccount as string);

        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_AS_VENDOR_BILLS_ENABLED, values.exportSettings?.exportAsVendorBillsEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_FORM_ID, values.exportSettings?.vendorBillFormId as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_CREDIT_FORM_ID, values.exportSettings?.vendorCreditFormId as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_APPROVAL_STATUS, values.exportSettings?.vendorBillApprovalStatus as string);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.VENDOR_BILL_CREDIT_CARDS_ENABLED, values.exportSettings?.vendorBillCreditCardsEnabled as boolean);
        secureLocalStorage.setItem(companyId + "_" + configurationId + "_" + ExpenseSettings.EXPORT_EXPENSE_RECEIPTS_ENABLED, values.exportSettings?.expenseReceiptsEnabled as boolean);

        setConfiguration({
            expense: {
                importEnabled: true,
                exportEnabled: values.exportSettings?.exportExpenseReportsEnabled || values.exportSettings?.exportAsVendorBillsEnabled,
                importExpenseSettings: getImportExpenseSettings(),
                exportExpenseSettings: getExportExpenseSettings()
            }
        })
    }, [values]);// eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <ViewContainer data-qa="expenses-page">
            <Typography verticalSpacingSize={1} variant="h4" data-qa="title-expenses-page">{t('expenses')}</Typography>
            <Typography data-qa="sub-title-expenses">{t('subTitleExpense')}</Typography>

            <form data-qa="import-expense-form" onSubmit={(e) => {
                e.preventDefault()
            }}>
                <Box verticalSpacingSize={6} verticalSpacingPosition="top">
                    <FormControlLabel data-qa="expense-import-categories-expense-settings"
                                      verticalSpacingSize={1}
                                      control={
                                          <Controller
                                              render={(props) => {
                                                  return (
                                                      <Switch
                                                          data-qa="switch-expense-import-categories-expense-settings"
                                                          color="primary"
                                                          checked={values.importSettings?.importExpenseCategoriesEnabled}
                                                          onChange={(event) => {
                                                              return props.field.onChange(event);
                                                          }}
                                                      />
                                                  );
                                              }}
                                              control={control}
                                              defaultValue={values.importSettings?.importExpenseCategoriesEnabled as boolean}
                                              name="importSettings.importExpenseCategoriesEnabled"
                                          />
                                      }
                                      label={<Typography variant="h5">{t('importExpenseCategories')}</Typography>}/>

                    <SwitchTypography dataQA="synchronize-expense-categories-helper-text-expense-settings"
                                      value={t('synchronizeExpenseCategoriesHelperText')}/>

                </Box>


                {featureFlags.customFieldsFeatureEnabled && <Box verticalSpacingSize={6} verticalSpacingPosition="top">
                    <FormControlLabel
                        data-qa="synchronize-netsuite-expense-custom-fields-expense-settings"
                        verticalSpacingSize={1}
                        control={
                            <Controller
                                render={(props) => {
                                    return (
                                        <Switch
                                            data-qa="switch-synchronize-netsuite-expense-custom-fields-expense-settings"
                                            color="primary"
                                            checked={values.importSettings?.synchronizeNetsuiteExpenseCustomFieldsEnabled}
                                            onChange={(event) => {
                                                setValue("importSettings.netsuiteCustomFieldsToImport", []);
                                                return props.field.onChange(event);
                                            }}
                                        />
                                    );
                                }}
                                control={control}
                                defaultValue={values.importSettings?.synchronizeNetsuiteExpenseCustomFieldsEnabled}
                                name="importSettings.synchronizeNetsuiteExpenseCustomFieldsEnabled"
                            />
                        }
                        value={values.importSettings?.synchronizeNetsuiteExpenseCustomFieldsEnabled}
                        label={<Typography variant="h5">{t('synchronizeNetsuiteExpenseCustomFields')}</Typography>}/>

                    <SwitchTypography dataQA="synchronize-netsuite-expense-custom-fields-helper-text-expense-settings"
                                      value={t('synchronizeNetsuiteExpenseCustomFieldsHelperText')}/>

                    {values.importSettings?.synchronizeNetsuiteExpenseCustomFieldsEnabled && <Controller
                        render={(props) => {
                            return (
                                <Autocomplete id="selectNetsuiteCustomFieldsToImport"
                                              data-qa="select-netsuite-custom-fields-to-import"
                                              className="widthField switchLeftMargin"
                                              label={t('selectNetsuiteCustomFieldsToImport')}
                                              verticalSpacingSize={3}
                                              verticalSpacingPosition="top"
                                              multiple
                                              showCheckbox
                                              limitTags={5}
                                              options={Array.from(netSuiteCustomExpenseFields.keys())}
                                              getOptionLabel={(option) => {
                                                  const optionLabel = netSuiteCustomExpenseFields.get(option) as string;
                                                  if(optionLabel === undefined && option !== undefined) {
                                                      return option + ' (Inactive)'
                                                  }
                                                  return optionLabel;
                                              }}
                                              onChange={(_, data) => {
                                                  return props.field.onChange(data);
                                              }}
                                              value={values.importSettings?.netsuiteCustomFieldsToImport as string[]}
                                              error={isValuesExistsKeyMap(values.importSettings?.netsuiteCustomFieldsToImport, netSuiteCustomExpenseFields)}
                                              helperText={isValuesExistsKeyMap(values.importSettings?.netsuiteCustomFieldsToImport, netSuiteCustomExpenseFields) ? t('inactiveNetSuiteCustomField') : ""}
                                              slotProps={{
                                                  clearIndicator: {'data-qa': 'select-netsuite-custom-fields-to-import-clear-indicator-vendors'},
                                                  popupIndicator: {'data-qa': 'select-netsuite-custom-fields-to-import-popup-indicator-vendors'},
                                                  popper: {'data-qa': 'select-netsuite-custom-fields-to-import-popper-vendors'},
                                                  paper: {'data-qa': 'select-netsuite-custom-fields-to-import-paper-vendors'}
                                              }}
                                />
                            );
                        }}
                        control={control}
                        name="importSettings.netsuiteCustomFieldsToImport"
                    />
                    }
                </Box>
                }


                <Box verticalSpacingSize={6} verticalSpacingPosition="top">
                    <FormControlLabel
                        data-qa="multiple-currencies-expense-settings"
                        verticalSpacingSize={1}
                        control={
                            <Controller
                                render={(props) => {
                                    return (
                                        <Switch data-qa="switch-multiple-currencies-expense-settings"
                                                color="primary"
                                                checked={values.importSettings?.multipleCurrenciesEnabled}
                                                onChange={(event) => {
                                                    setValue("importSettings.overrideForeignAmountAndExchangeRateEnabled", false);
                                                    return props.field.onChange(event);
                                                }}
                                        />
                                    );
                                }}
                                control={control}
                                defaultValue={values.importSettings?.multipleCurrenciesEnabled as boolean}
                                name="importSettings.multipleCurrenciesEnabled"
                            />
                        }
                        label={<Typography variant="h5">{t('multipleCurrencies')}</Typography>}/>

                    <SwitchTypography dataQA="multiple-currencies-helper-text-expense-settings"
                                      value={t('multipleCurrenciesHelperText')}/>

                    {values.importSettings?.multipleCurrenciesEnabled && <Box className="switchLeftMargin">
                        <FormControlLabel
                            data-qa="override-foreign-amount-and-exchange-rate-expense-settings"
                            verticalSpacingSize={2}
                            verticalSpacingPosition="top"
                            control={
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Checkbox
                                                data-qa="checkbox-override-foreign-amount-and-exchange-rate-expense-settings"
                                                color="primary"
                                                checked={values.importSettings?.overrideForeignAmountAndExchangeRateEnabled}
                                                onChange={(event) => {
                                                    return props.field.onChange(event);
                                                }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    defaultValue={values.importSettings?.overrideForeignAmountAndExchangeRateEnabled as boolean}
                                    name="importSettings.overrideForeignAmountAndExchangeRateEnabled"
                                />
                            }
                            label={<Typography
                                variant="subtitle1">{t('overrideForeignAmountAndExchangeRate')}</Typography>}/>
                        <CheckBoxTypography
                            dataQA="override-foreign-amount-and-exchange-rate-sub-title-expense-settings"
                            value={t('overrideForeignAmountAndExchangeRateHelperText')}/>
                    </Box>
                    }
                </Box>


                {featureFlags.netsuiteTaxesFeatureEnabled &&
                    <Box verticalSpacingSize={6} verticalSpacingPosition="top">
                        <FormControlLabel
                            data-qa="netsuite-suite-taxes-expense-settings"
                            verticalSpacingSize={1}
                            control={
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Switch data-qa="switch-netsuite-suite-taxes-expense-settings"
                                                    color="primary"
                                                    checked={values.importSettings?.netsuiteSuiteTaxesEnabled}
                                                    onChange={(event) => {
                                                        setValue("importSettings.netsuiteTaxableRegions", []);
                                                        return props.field.onChange(event);
                                                    }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    defaultValue={values.importSettings?.netsuiteSuiteTaxesEnabled as boolean}
                                    name="importSettings.netsuiteSuiteTaxesEnabled"
                                />
                            }
                            label={<Typography variant="h5">{t('netsuiteSuiteTaxes')}</Typography>}/>

                        <SwitchTypography dataQA="netsuite-suite-taxes-helper-text-expense-settings"
                                          value={t('netsuiteSuiteTaxesHelperText')}/>


                        {values.importSettings?.netsuiteSuiteTaxesEnabled && <Controller
                            render={(props) => {
                                return (
                                    <Autocomplete id="netsuiteTaxableRegions"
                                                  data-qa="netsuite-taxable-regions"
                                                  className="widthField switchLeftMargin"
                                                  label={t('netsuiteTaxableRegions')}
                                                  verticalSpacingSize={3}
                                                  verticalSpacingPosition="top"
                                                  multiple
                                                  required
                                                  showCheckbox
                                                  limitTags={5}
                                                  options={Object.keys(countriesList)}
                                                  getOptionLabel={(option) => {
                                                      return `${getValueByKey(countriesList, option)}`
                                                  }}
                                                  onChange={(_, data) => {
                                                      return props.field.onChange(data);
                                                  }}
                                                  value={values.importSettings?.netsuiteTaxableRegions ?? []}
                                                  error={!!errors.importSettings?.netsuiteTaxableRegions}
                                                  onBlur={() => {
                                                      if(isNullOrEmpty(values.importSettings?.netsuiteTaxableRegions)) {
                                                          setError('importSettings.netsuiteTaxableRegions', {type: "required"});
                                                      }
                                                  }}
                                                  helperText={errors.importSettings?.netsuiteTaxableRegions ? t('netsuiteTaxableRegionsError') : ""}
                                                  slotProps={{
                                                      clearIndicator: {'data-qa': 'netsuite-taxable-regions-clear-indicator-vendors'},
                                                      popupIndicator: {'data-qa': 'netsuite-taxable-regions-popup-indicator-vendors'},
                                                      popper: {'data-qa': 'netsuite-taxable-regions-popper-vendors'},
                                                      paper: {'data-qa': 'netsuite-taxable-regions-paper-vendors'}
                                                  }}
                                    />
                                );
                            }}
                            control={control}
                            name="importSettings.netsuiteTaxableRegions"
                        />
                        }
                    </Box>
                }
            </form>


            <form data-qa="export-expense-form" onSubmit={(e) => {
                e.preventDefault()
            }}>
                {syncEmployees && <Box verticalSpacingSize={6} verticalSpacingPosition="top">
                    <FormControlLabel
                        data-qa="export-expense-reports-expense-settings"
                        verticalSpacingSize={1}
                        control={
                            <Controller
                                render={(props) => {
                                    return (
                                        <Switch data-qa="switch-export-expense-reports-expense-settings"
                                                color="primary"
                                                checked={values.exportSettings?.exportExpenseReportsEnabled}
                                                onChange={(event: any) => {
                                                    setValue("exportSettings.expenseReportFormId", "");
                                                    setValue("exportSettings.reportNumberPrefix", "");
                                                    setValue("exportSettings.exportExpenseReportAsCompleteEnabled", false);
                                                    setValue("exportSettings.settSupervisorApprovalEnabled", false);
                                                    setValue("exportSettings.settAccountingApprovalEnabled", false);
                                                    setValue("exportSettings.payableAccount", "");
                                                    setValue("exportSettings.expenseReportIncludingCorporateCardsEnabled", false);
                                                    setValue("exportSettings.corporateCardAccount", "");

                                                    clearErrors("exportSettings.expenseReportFormId");
                                                    clearErrors("exportSettings.reportNumberPrefix");
                                                    clearErrors("exportSettings.payableAccount");

                                                    return props.field.onChange(event);
                                                }}
                                        />
                                    );
                                }}
                                control={control}
                                defaultValue={values.exportSettings?.exportExpenseReportsEnabled as boolean}
                                name="exportSettings.exportExpenseReportsEnabled"
                            />
                        }
                        label={<Typography variant="h5">{t('exportExpenseReports')}</Typography>}/>

                    <SwitchTypography dataQA="export-expense-reports-sub-title-expense-settings"
                                      value={t('exportExpenseReportsHelperText')}/>

                    {values.exportSettings?.exportExpenseReportsEnabled && <Box className="switchLeftMargin">

                        <TextField id="expense-report-form-id"
                                   data-qa="expense-report-form-id-expense-settings"
                                   className="widthField"
                                   inputProps={{"data-qa": "inner-expense-report-form-id-expense-settings"}}
                                   type="text"
                                   verticalSpacingSize={3}
                                   verticalSpacingPosition="top"
                                   label={t('expenseReportFormId')}
                                   {...register('exportSettings.expenseReportFormId',
                                       {
                                           validate: {
                                               Validate(value) {
                                                   if(value !== undefined) {
                                                       return (!digitPattern.test(value));
                                                   }
                                               }
                                           },
                                           onBlur(event: any) {
                                               if(digitPattern.test(event.target.value)) {
                                                   setValue("exportSettings.expenseReportFormId", "");
                                               }
                                           }
                                       })}
                                   InputLabelProps={{shrink: !!watch('exportSettings.expenseReportFormId')}}
                                   error={!!errors.exportSettings?.expenseReportFormId || digitPattern.test(values.exportSettings?.expenseReportFormId as string)}
                                   FormHelperTextProps={{'data-qa': 'expense-report-form-id-helperText'}}
                                   helperText={(errors.exportSettings?.expenseReportFormId || (digitPattern.test(values.exportSettings?.expenseReportFormId as string))) ? t('integerValidationMessage') : t('expenseReportFormIdHelperText')}/>

                        <TextField id="report-number-prefix"
                                   data-qa="report-number-prefix-expense-settings"
                                   inputProps={{"data-qa": "inner-report-number-prefix-expense-settings"}}
                                   className="widthField"
                                   type="text"
                                   verticalSpacingSize={3}
                                   verticalSpacingPosition="top"
                                   label={t('reportNumberPrefix')}
                                   {...register('exportSettings.reportNumberPrefix',
                                       {
                                           validate: {
                                               Validate(value) {
                                                   if(value !== undefined) {
                                                       return (!textFieldPattern.test(value));
                                                   }
                                               }
                                           },
                                           onBlur(event: any) {
                                               if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                   setValue("exportSettings.reportNumberPrefix", "");
                                               }
                                           }
                                       })}
                                   InputLabelProps={{shrink: !!watch('exportSettings.reportNumberPrefix')}}
                                   error={!!errors.exportSettings?.reportNumberPrefix}
                                   FormHelperTextProps={{'data-qa': 'report-number-prefix-helperText'}}
                                   helperText={errors.exportSettings?.reportNumberPrefix ? t('textFieldValidationMessage') : t('reportNumberPrefixHelperText')}/>

                        <FormControlLabel
                            data-qa="export-expense-report-as-complete-expense-settings"
                            verticalSpacingSize={3}
                            verticalSpacingPosition="top"
                            control={
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Checkbox
                                                data-qa="checkbox-export-expense-report-as-complete-expense-settings"
                                                color="primary"
                                                checked={values.exportSettings?.exportExpenseReportAsCompleteEnabled}
                                                onChange={(event: any) => {
                                                    return props.field.onChange(event);
                                                }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    defaultValue={values.exportSettings?.exportExpenseReportAsCompleteEnabled}
                                    name="exportSettings.exportExpenseReportAsCompleteEnabled"
                                />
                            }
                            label={<Typography
                                variant="subtitle1">{t('exportExpenseReportAsComplete')}</Typography>}/>

                        <CheckBoxTypography dataQA="export-expense-report-as-complete-expense-settings-helper-text"
                                            value={t('exportExpenseReportAsCompleteHelperText')}/>

                        <FormControlLabel
                            data-qa="set-supervisor-approval-expense-settings"
                            verticalSpacingSize={3}
                            verticalSpacingPosition="top"
                            control={
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Checkbox data-qa="checkbox-set-supervisor-approval-expense-settings"
                                                      color="primary"
                                                      checked={values.exportSettings?.settSupervisorApprovalEnabled}
                                                      onChange={(event: any) => {
                                                          return props.field.onChange(event);
                                                      }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    defaultValue={values.exportSettings?.settSupervisorApprovalEnabled}
                                    name="exportSettings.settSupervisorApprovalEnabled"
                                />
                            }
                            label={<Typography variant="subtitle1">{t('setSupervisorApproval')}</Typography>}/>

                        <CheckBoxTypography dataQA="set-supervisor-approval-expense-settings-helper-text"
                                            value={t('setSupervisorApprovalHelperText')}/>

                        <FormControlLabel
                            data-qa="set-accounting-approval-expense-settings"
                            verticalSpacingSize={3}
                            verticalSpacingPosition="top"
                            control={
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Checkbox data-qa="checkbox-set-accounting-approval-expense-settings"
                                                      color="primary"
                                                      checked={values.exportSettings?.settAccountingApprovalEnabled}
                                                      onChange={(event: any) => {
                                                          setValue("exportSettings.payableAccount", "");
                                                          clearErrors("exportSettings.payableAccount");
                                                          return props.field.onChange(event);
                                                      }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    defaultValue={values.exportSettings?.settAccountingApprovalEnabled}
                                    name="exportSettings.settAccountingApprovalEnabled"
                                />
                            }
                            label={<Typography variant="subtitle1">{t('setAccountingApproval')}</Typography>}/>

                        <CheckBoxTypography dataQA="set-accounting-approval-expense-settings-helper-text"
                                            value={t('setAccountingApprovalHelperText')}/>

                        {values.exportSettings?.settAccountingApprovalEnabled &&
                            <Box verticalSpacingSize={3}
                                 verticalSpacingPosition="top"
                                 className="checkboxLeftMargin">
                                <TextField id="payableAccount"
                                           data-qa="accounts-payable-account-expense-settings"
                                           className="widthField"
                                           inputProps={{"data-qa": "inner-accounts-payable-account-expense-settings"}}
                                           type="text"
                                           label={t('payableAccount')}
                                           {...register('exportSettings.payableAccount', {
                                               validate: {
                                                   Validate(value) {
                                                       if(value !== undefined) {
                                                           return (!textFieldPattern.test(value));
                                                       }
                                                   }
                                               },
                                               onBlur(event: any) {
                                                   if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                       setValue("exportSettings.payableAccount", "");
                                                   }
                                               }
                                           })}
                                           InputLabelProps={{shrink: !!watch('exportSettings.payableAccount')}}
                                           FormHelperTextProps={{'data-qa': 'accounts-payable-account-helperText'}}
                                           helperText={errors.exportSettings?.payableAccount ? t('textFieldValidationMessage') : t('payableAccountHelperText')}
                                           error={!!errors.exportSettings?.payableAccount}/>
                            </Box>
                        }
                        {syncEmployees && <Box verticalSpacingSize={3} verticalSpacingPosition="top">
                            <FormControlLabel
                                data-qa="expense-report-including-corporate-cards-expense-settings"
                                control={
                                    <Controller
                                        render={(props) => {
                                            return (
                                                <Checkbox
                                                    data-qa="checkbox-expense-report-including-corporate-cards-expense-settings"
                                                    color="primary"
                                                    checked={values.exportSettings?.expenseReportIncludingCorporateCardsEnabled}
                                                    onChange={(event: any) => {
                                                        setValue("exportSettings.corporateCardAccount", "");
                                                        clearErrors("exportSettings.corporateCardAccount");
                                                        return props.field.onChange(event);
                                                    }}
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={values.exportSettings?.expenseReportIncludingCorporateCardsEnabled as boolean}
                                        name="exportSettings.expenseReportIncludingCorporateCardsEnabled"
                                    />
                                }
                                label={<Typography
                                    variant="subtitle1">{t('expenseReportIncludingCorporateCards')}</Typography>}/>

                            <CheckBoxTypography
                                dataQA="expense-report-including-corporate-cards-sub-title-expense-settings"
                                value={t('expenseReportIncludingCorporateCardsHelperText')}/>

                            {values.exportSettings.expenseReportIncludingCorporateCardsEnabled &&
                                featureFlags.expenseReportCorporateCardAccountFeatureEnabled &&
                                <Box verticalSpacingSize={3}
                                     verticalSpacingPosition="top"
                                     className="checkboxLeftMargin">
                                    <TextField id="corporate-card-account-id"
                                               data-qa="corporate-card-account-expense-settings"
                                               className="widthField"
                                               inputProps={{"data-qa": "inner-corporate-card-account-expense-settings"}}
                                               type="text"
                                               label={t('corporateCardAccount')}
                                               {...register('exportSettings.corporateCardAccount',
                                                   {
                                                       validate: {
                                                           Validate(value) {
                                                               if(value !== undefined) {
                                                                   return (!textFieldPattern.test(value));
                                                               }
                                                           }
                                                       },
                                                       onBlur(event: any) {
                                                           if(event.target.value.length !== 0 && textFieldPattern.test(event.target.value)) {
                                                               setValue("exportSettings.corporateCardAccount", "");
                                                           }
                                                       }
                                                   })}
                                               InputLabelProps={{shrink: !!watch('exportSettings.corporateCardAccount')}}
                                               error={!!errors.exportSettings?.corporateCardAccount}
                                               FormHelperTextProps={{'data-qa': 'corporate-card-account-helperText'}}
                                               helperText={errors.exportSettings?.corporateCardAccount ? t('textFieldValidationMessage') : t('corporateCardAccountHelperText')}/>
                                </Box>
                            }
                        </Box>
                        }
                    </Box>
                    }
                </Box>
                }


                {<Box verticalSpacingSize={6} verticalSpacingPosition="top">
                    <FormControlLabel
                        data-qa="export-as-vendor-bills-expense-settings"
                        verticalSpacingSize={1}
                        control={
                            <Controller
                                render={(props) => {
                                    return (
                                        <Switch data-qa="switch-export-as-vendor-bills-expense-settings"
                                                color="primary"
                                                checked={values.exportSettings?.exportAsVendorBillsEnabled}
                                                onChange={(event: any) => {
                                                    setValue("exportSettings.vendorBillFormId", "");
                                                    setValue("exportSettings.vendorCreditFormId", "");
                                                    setValue("exportSettings.vendorBillApprovalStatus", "");
                                                    setValue("exportSettings.vendorBillCreditCardsEnabled", false);
                                                    clearErrors("exportSettings.vendorBillFormId");
                                                    clearErrors("exportSettings.vendorCreditFormId");
                                                    clearErrors("exportSettings.vendorBillApprovalStatus");
                                                    clearErrors("exportSettings.vendorBillCreditCardsEnabled");
                                                    return props.field.onChange(event);
                                                }}
                                        />
                                    );
                                }}
                                control={control}
                                defaultValue={values.exportSettings?.exportAsVendorBillsEnabled as boolean}
                                name="exportSettings.exportAsVendorBillsEnabled"
                            />
                        }
                        label={<Typography variant="h5">{t('exportAsVendorBills')}</Typography>}/>

                    <SwitchTypography dataQA="export-as-vendor-bills-sub-title-expense-settings"
                                      value={t('exportAsVendorBillsHelperText')}/>

                    {values.exportSettings?.exportAsVendorBillsEnabled && <Box className="switchLeftMargin">

                        <TextField id="vendor-bill-form-id"
                                   data-qa="vendor-bill-form-id-expense-settings"
                                   className="widthField"
                                   inputProps={{"data-qa": "inner-vendor-bill-form-id-expense-settings"}}
                                   type="text"
                                   verticalSpacingSize={6}
                                   verticalSpacingPosition="both"
                                   label={t('vendorBillFormId')}
                                   {...register('exportSettings.vendorBillFormId',
                                       {
                                           validate: {
                                               Validate(value) {
                                                   if(value !== undefined) {
                                                       return (!digitPattern.test(value));
                                                   }
                                               }
                                           },
                                           onBlur(event: any) {
                                               if(digitPattern.test(event.target.value)) {
                                                   setValue("exportSettings.vendorBillFormId", "");
                                               }
                                           }
                                       })}
                                   InputLabelProps={{shrink: !!watch('exportSettings.vendorBillFormId')}}
                                   error={!!errors.exportSettings?.vendorBillFormId || digitPattern.test(values.exportSettings?.vendorBillFormId as string)}
                                   FormHelperTextProps={{'data-qa': 'vendor-bill-form-id-helperText'}}
                                   helperText={errors.exportSettings?.vendorBillFormId || digitPattern.test(values.exportSettings?.vendorBillFormId as string) ? t('integerValidationMessage') : t('vendorBillFormIdHelperText')}/>

                        <TextField id="vendor-credit-form-id"
                                   data-qa="vendor-credit-form-id-expense-settings"
                                   className="widthField"
                                   inputProps={{"data-qa": "inner-vendor-credit-form-id-expense-settings"}}
                                   type="text"
                                   verticalSpacingSize={3}
                                   label={t('vendorCreditFormId')}
                                   {...register('exportSettings.vendorCreditFormId',
                                       {
                                           validate: {
                                               Validate(value) {
                                                   if(value !== undefined) {
                                                       return (!digitPattern.test(value));
                                                   }
                                               }
                                           },
                                           onBlur(event: any) {
                                               if(digitPattern.test(event.target.value)) {
                                                   setValue("exportSettings.vendorCreditFormId", "");
                                               }
                                           }
                                       })}
                                   InputLabelProps={{shrink: !!watch('exportSettings.vendorCreditFormId')}}
                                   error={!!errors.exportSettings?.vendorCreditFormId || digitPattern.test(values.exportSettings?.vendorCreditFormId as string)}
                                   FormHelperTextProps={{'data-qa': 'vendor-credit-form-id-helperText'}}
                                   helperText={(errors.exportSettings?.vendorCreditFormId || digitPattern.test(values.exportSettings?.vendorCreditFormId as string)) ? t('integerValidationMessage') : t('vendorCreditFormIdHelperText')}/>

                        <Controller
                            render={(props) => {
                                return (
                                    <Autocomplete id="vendorBillApprovalStatus"
                                                  data-qa="vendor-bill-approval-status-expense-settings"
                                                  className="widthField"
                                                  label={t('vendorBillApprovalStatus')}
                                                  verticalSpacingSize={3}
                                                  disableClearable
                                                  options={Object.keys(vendorBillApprovalStatus)}
                                                  getOptionLabel={(option) => {
                                                      return `${getValueByKey(vendorBillApprovalStatus, option)}`;
                                                  }}
                                                  onChange={(_, data) => {
                                                      data = (data === null) ? '' : data;
                                                      return props.field.onChange(data);
                                                  }}
                                                  value={getDefaultVendorBillApprovalStatus(values.exportSettings?.vendorBillApprovalStatus)}
                                                  slotProps={{
                                                      clearIndicator: {'data-qa': 'vendor-bill-approval-status-clear-indicator'},
                                                      popupIndicator: {'data-qa': 'vendor-bill-approval-status-popup-indicator'},
                                                      popper: {'data-qa': 'vendor-bill-approval-status-popper'},
                                                      paper: {'data-qa': 'vendor-bill-approval-status-paper'}
                                                  }}
                                    />
                                );
                            }}
                            control={control}
                            name="exportSettings.vendorBillApprovalStatus"
                        />

                        <FormControlLabel
                            data-qa="vendor-bill-credit-cards-label"
                            checked={values.exportSettings?.vendorBillCreditCardsEnabled}
                            control={
                                <Controller
                                    render={(props) => {
                                        return (
                                            <Checkbox data-qa="vendor-bill-credit-cards-checkbox"
                                                      color="primary"
                                                      checked={values.exportSettings?.vendorBillCreditCardsEnabled}
                                                      onChange={(event: any) => {
                                                          return props.field.onChange(event);
                                                      }}
                                            />
                                        );
                                    }}
                                    control={control}
                                    name="exportSettings.vendorBillCreditCardsEnabled"
                                />
                            }
                            label={<Typography variant="subtitle1"
                                               data-qa="vendor-bill-credit-cards-text-label">{t('vendorBillCreditCardsText')}</Typography>}/>

                        <CheckBoxTypography dataQA="vendor-bill-credit-cards-helper-text-label"
                                            value={t('vendorBillCreditCardsHelperText')}/>
                    </Box>
                    }
                </Box>
                }


                {featureFlags.exportExpenseReceiptsFeatureEnabled &&
                    <Box verticalSpacingSize={6} verticalSpacingPosition="top">
                        <FormControlLabel data-qa="export-expense-attachments-expense-settings"
                                          verticalSpacingSize={1}
                                          control={
                                              <Controller
                                                  render={(props) => {
                                                      return (
                                                          <Switch
                                                              data-qa="switch-export-expense-attachments-expense-settings"
                                                              color="primary"
                                                              checked={values.exportSettings?.expenseReceiptsEnabled}
                                                              onChange={(event) => {
                                                                  return props.field.onChange(event);
                                                              }}
                                                          />
                                                      );
                                                  }}
                                                  control={control}
                                                  defaultValue={values.exportSettings?.expenseReceiptsEnabled as boolean}
                                                  name="exportSettings.expenseReceiptsEnabled"
                                              />
                                          }
                                          label={<Typography
                                              variant="h5">{t('exportExpenseAttachments')}</Typography>}/>

                        <SwitchTypography dataQA="export-expense-attachments-helper-text-expense-settings"
                                          value={t('exportExpenseAttachmentsHelperText')}/>

                    </Box>
                }
                <Spacer direction="vertical" size={24}/>
            </form>
        </ViewContainer>

        <BottomNavBar
            prevDataQA="back-to-customers-and-projects-button-navigation"
            prevText={t('customersAndProjects')}
            prevRoutes={RouteLink.CustomersAndProjects}
            nextDataQA="next-to-review-button-navigation"
            nextText={t('review')}
            nextRoutes={RouteLink.Review}/>
    </>
}